import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContext } from '../../contexts/ModalContext';

/**
 * 팝업
 * 프로모션 쿠폰 지급 전 로그인 페이지
 */
export default function PromotionSignInModal() {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);

  return (
    <div className="text-white relative flex flex-col justify-center items-center gap-8 w-[270px]  py-6 p-4 bg-[#131313]">
      <div className="flex flex-col justify-center items-center gap-2 px-4">
        <h1>{t('promotion.afterSignup')}</h1>
        <h1>{t('promotion.getFreeCoupon')}</h1>
      </div>
      <footer className="flex justify-around w-full gap-2">
        {/* <a
              className="w-full flex justify-center items-center h-[3.5rem] mx-auto rounded-xl border border-N1 text-N2 text-lg bg-gradient-to-r from-[#473CBC] to-[#202E67]"
              href="https://tosto.re/soundpill1"
            >
              {t("web.openApp")}
            </a> */}
        <button onClick={() => handleModal('')} className="w-full text-white/40">
          {t('successModal.confirm')}
        </button>
      </footer>
    </div>
  );
}
