import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthStateContext } from '../../contexts/AuthContext';
import { PromotionContext } from '../../contexts/PromotionContext';
import { ModalContext } from '../../contexts/ModalContext';
import PromotionSignInModal from '../../components/modals/PromotionSignInModal';
import i18n from '../../locales/i18n';
import { LocalLanguage, setItem } from '../../utils/localStorage';

export default function CouponPage() {
  /** Initialization */
  const { state, setState, registerCouponAndUseCoupon } = useContext(PromotionContext);
  const { isAuthentication } = useContext(AuthStateContext);
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParam] = useSearchParams();
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    const codeParam = searchParam.get('code');
    const lang = searchParam.get('lang');

    if (lang === 'en' || lang === 'ko') {
      i18n.changeLanguage(lang);
      setItem(LocalLanguage, lang);
    }

    setState({
      ...state,
      redirectUrl: `${location.pathname}?${searchParam.toString()}`,
      isPromotionPageEntered: true,
      couponCode: codeParam ? codeParam : '',
    });
  }, [searchParam]);

  /** Handlers */
  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isAuthentication) {
      handleModal(<PromotionSignInModal />);
      navigate('/auth');
      return;
    }

    try {
      await registerCouponAndUseCoupon();
      navigate('/app/home');
    } catch (err: any) {
      setError(err.message || 'An error occurred while registering the coupon.');
    }
  };

  return (
    <div className="w-full h-full flex flex-col bg-N7">
      <div
        // className="w-full pt-[3%] pb-[30%] md:pb-[10%] pl-[30%] md:pl-[45%] text-white font-bold text-[40px]"
        className="w-full text-center text-white font-bold text-4xl py-12"
      >
        SoundPill
      </div>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="flex flex-col rounded-xl bg-N8 drop-shadow-lg py-12 px-8 sm:py-18 sm:px-12 mx-4 gap-8 sm:max-w-xl md:mx-0">
          <div className="w-full text-N1 text-lg sm:text-2xl">
            <h1>{t('couponPage.registerCoupon')}</h1>
          </div>
          <form className="flex flex-col justify-between gap-8 text-N2" onSubmit={handleRegister}>
            <input
              type="text"
              name="couponCode"
              value={state.couponCode}
              onChange={(e) =>
                setState({
                  ...state,
                  couponCode: e.currentTarget.value,
                })
              }
              placeholder={t('couponPage.enterCouponCode')}
              className="w-full px-5 py-3 bg-N8 border border-N4 rounded-xl font-bold text-3xl"
            />
            <button
              // disabled={couponCode.length < 8}
              className="flex-shrink-1 w-full h-[3.5rem] mx-auto rounded-xl border border-N1 text-N2 text-lg bg-gradient-to-r from-[#473CBC] to-[#202E67]"
              type="submit"
            >
              {t('couponPage.submit')}
            </button>
          </form>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <ul className="w-full text-N4 text-[14px]">
            <li>{t('couponPage.couponUsage')}</li>
            <li>{t('couponPage.couponHistory')}</li>
            <li>{t('couponPage.couponBenefits')}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
