import { Route, Routes, useLocation } from 'react-router-dom';
import DBSigninPage from '../pages/DBInsurance/DBSigninPage';
import { DBSurveyStart } from '../pages/DBInsurance/DBSurveyStart';
import { ResultAfterSurvey } from '../pages/DBInsurance/ResultAfterSurvey';
import { SoundRecommendations } from '../pages/DBInsurance/SoundRecommendations';
import { SurveyQuestions } from '../pages/DBInsurance/SurveyQuestions';
import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';

const analyticsKey = 'G-RSSB006PDC';
let perviousURI = '';
export const AISurveyRoute = () => {
  const location = useLocation();
  const initializedRef = useRef(false);
  useEffect(() => {
    if (!initializedRef.current) ReactGA.initialize(analyticsKey);
    initializedRef.current = true;
    console.log(`🚀 ~ useEffect ~ ReactGA.initialize(${analyticsKey});:`);
  }, []);
  let uri = `${window.location.protocol}//${window.location.host}${location.pathname}`;
  if (location?.search) uri += `[${location.search.substring(1)}]`;
  useEffect(() => {
    if (initializedRef.current && perviousURI !== uri) {
      perviousURI = uri;
      ReactGA.send({ hitType: 'pageview', page: uri, title: uri });
      console.log(`🚀 ~ useEffect ~ ReactGA.send({ hitType: 'pageview', page: ${uri}, title: uri});:`);
    }
  }, [uri]);
  return (
    <Routes>
      <Route path="/" element={<DBSurveyStart />} />
      <Route path="survey-questions" element={<SurveyQuestions />} />
      <Route path="survey-result" element={<ResultAfterSurvey />} />
      <Route path="recommended-sound" element={<SoundRecommendations />} />
      <Route path="signin" element={<DBSigninPage />} />
    </Routes>
  );
};
